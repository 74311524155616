import Image from "next/image";
import { useTranslations } from "next-intl";
import { Dispatch, SetStateAction } from "react";

import imageUtils from "~/utils/image-utils";

import styles from "./social-wall-utils.module.scss";

type SocialWallCarouselProps = {
  data: {
    id: string;
    type: string;
    post_image_cdn: string;
    post_link: string;
    userlink?: string;
    external_name: string;
    external_post_id: string;
  };
  index: number;
  setSocialWallDialogOpened: Dispatch<SetStateAction<boolean>>;
  socialWallDialogOpened: boolean;
  setSocialWallIndex: Dispatch<SetStateAction<number>>;
};

export function SocialWallCarousel({
  data,
  index,
  setSocialWallDialogOpened,
  socialWallDialogOpened,
  setSocialWallIndex,
}: SocialWallCarouselProps) {
  const t = useTranslations();
  const userName = data?.userlink?.split("/");

  return (
    <button
      onClick={() => {
        setSocialWallDialogOpened(!socialWallDialogOpened);
        setSocialWallIndex(index);
      }}
      className={styles.wrapperSlide}
    >
      {data.post_image_cdn ? (
        <div className={styles.wrapperImage}>
          <Image
            src={data.post_image_cdn}
            alt={`${data.type} ${data.external_name}`}
            fill
            loader={imageUtils.socialWallImageLoader}
          />
        </div>
      ) : null}
      {userName && (
        <div className={styles.slideLink}>
          {`${t("generic.shared_by")} ${data?.type === "instagram" ? "@" : ""}${userName?.pop() || userName?.pop()} ${t(
            "generic.via"
          )} ${data.type}`}
        </div>
      )}
    </button>
  );
}

export function SocialWallMissingData() {
  const t = useTranslations();

  return (
    <div className={styles.somethingIsMissing}>
      <p className={styles.firstParagraph}>
        {t.rich("generic.oops_something_is_missing", {
          strong: (chunks) => <strong className={styles.bold}>{chunks}</strong>,
        })}
      </p>
      <p className={styles.secondParagraph}>
        {t.rich("generic.social_content_is_not_loading", {
          br: () => <br />,
        })}
      </p>
    </div>
  );
}

export function SocialWallSkeleton() {
  return (
    <div className={styles.wrapperSlide}>
      <div className={styles.wrapperImage} />
      <div className={styles.slideLink} />
    </div>
  );
}
