import classNames from "classnames";
import { Entry } from "contentful";
import { AnimatePresence, motion } from "framer-motion";

import MediaAsset from "~/components/common/media-asset";
import Editorials from "~/types/editorials";

import styles from "./mosaic.module.scss";
import MosaicSocialWall from "./mosaic-social-wall";

type Props = {
  entry: Entry<Editorials.TimedEditorialCarouselSlide> | Entry<Editorials.EditorialBanner>;
  isTextAbove?: boolean;
  mobileOnly?: boolean;
  onInteraction?: () => void;
};

export default function Mosaic({ entry, isTextAbove = false, mobileOnly = false, onInteraction }: Props) {
  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={entry.sys.id}
        initial={{ x: -10, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: 10, opacity: 0 }}
        className={classNames(
          styles.mosaic,
          mobileOnly ? styles.mobileOnly : undefined,
          entry.fields.flipTextOnMobile
            ? isTextAbove
              ? styles.moveToFirstPositionOnMobile
              : styles.moveToLastPositionOnMobile
            : undefined,
          styles[entry.fields.mosaicVariant],
          isTextAbove && entry.fields.flipTextOnMobile ? styles.flipMargin : undefined
        )}
        onClick={() => onInteraction?.()}
      >
        {entry.fields.assets && entry.fields.mosaicVariant === "mosaic_1" ? (
          <>
            <div
              className={classNames(
                styles.mosaicMedia,
                entry.fields.flipMosaicOnMobile ? styles.moveToLastPositionOnMobile : undefined
              )}
              style={{
                aspectRatio: "16/9",
                width: "100%",
              }}
            >
              <MediaAsset entry={entry.fields.assets[0]} />
            </div>
            <div className={styles.mosaicRow}>
              {entry.fields.assets[1] ? (
                <div className={styles.mosaicMedia} style={{ aspectRatio: "1/1", flexGrow: "calc(1/1)" }}>
                  <MediaAsset entry={entry.fields.assets[1]} />
                </div>
              ) : null}
              {entry.fields.assets[2] ? (
                <div className={styles.mosaicMedia} style={{ aspectRatio: "16/9", flexGrow: "calc(16/9)" }}>
                  <MediaAsset entry={entry.fields.assets[2]} />
                </div>
              ) : null}
            </div>
          </>
        ) : null}
        {entry.fields.assets && entry.fields.mosaicVariant === "mosaic_2" ? (
          <>
            <div className={styles.mosaicMedia} style={{ gridRow: "1 / 3" }}>
              <MediaAsset entry={entry.fields.assets[0]} />
            </div>
            {entry.fields.assets[1] ? (
              <div className={styles.mosaicMedia} style={{ aspectRatio: "1/1" }}>
                <MediaAsset entry={entry.fields.assets[1]} />
              </div>
            ) : null}
            {entry.fields.assets[2] ? (
              <div className={styles.mosaicMedia} style={{ aspectRatio: "1/1" }}>
                <MediaAsset entry={entry.fields.assets[2]} />
              </div>
            ) : null}
          </>
        ) : null}
        {entry.fields.assets && entry.fields.mosaicVariant === "mosaic_3" ? (
          <>
            <div className={styles.mosaicMedia} style={{ aspectRatio: "1/1" }}>
              <MediaAsset entry={entry.fields.assets[0]} />
            </div>
          </>
        ) : null}
        {entry.fields.assets && entry.fields.mosaicVariant === "mosaic_4" ? (
          <>
            <div className={styles.mosaicMedia} style={{ aspectRatio: "16/9", width: "100%" }}>
              <MediaAsset entry={entry.fields.assets[0]} />
            </div>
          </>
        ) : null}
        {entry.fields.assets && entry.fields.mosaicVariant === "mosaic_5" ? (
          <>
            <div className={styles.mosaicMedia} style={{ aspectRatio: "1/1" }}>
              <MediaAsset entry={entry.fields.assets[0]} />
            </div>
            {entry.fields.assets[1] ? (
              <div className={styles.mosaicMedia} style={{ aspectRatio: "1/1" }}>
                <MediaAsset entry={entry.fields.assets[1]} />
              </div>
            ) : null}
            {entry.fields.assets[2] ? (
              <div
                className={classNames(
                  styles.mosaicMedia,
                  entry.fields.flipMosaicOnMobile ? styles.moveToFirstPositionOnMobile : undefined
                )}
                style={{ aspectRatio: "4/5" }}
              >
                <MediaAsset entry={entry.fields.assets[2]} />
              </div>
            ) : null}
          </>
        ) : null}
        {entry.fields.assets && entry.fields.mosaicVariant === "mosaic_6" ? (
          <>
            <div
              className={classNames(
                styles.mosaicMedia,
                entry.fields.flipMosaicOnMobile ? styles.moveToLastPositionOnMobile : undefined
              )}
              style={{ aspectRatio: "4/5" }}
            >
              <MediaAsset entry={entry.fields.assets[0]} />
            </div>
            {entry.fields.assets[1] ? (
              <div className={styles.mosaicMedia} style={{ aspectRatio: "1/1" }}>
                <MediaAsset entry={entry.fields.assets[1]} />
              </div>
            ) : null}
            {entry.fields.assets[2] ? (
              <div className={styles.mosaicMedia} style={{ aspectRatio: "1/1" }}>
                <MediaAsset entry={entry.fields.assets[2]} />
              </div>
            ) : null}
          </>
        ) : null}
        {/* for editorial banner */}
        {entry.fields.assets && entry.fields.mosaicVariant === "mosaic_7" ? (
          <>
            <div
              className={classNames(
                styles.mosaicMedia,
                entry.fields.flipMosaicOnMobile ? styles.moveToLastPositionOnMobile : undefined,
                entry.fields.assets.length === 3 ? styles.threeMosaicMedia : undefined,
                entry.fields.assets.length === 4 ? styles.fourMosaicMedia : undefined
              )}
              style={{ aspectRatio: "1/1" }}
            >
              <MediaAsset entry={entry.fields.assets[0]} />
            </div>
            {entry.fields.assets[1] ? (
              <div
                className={classNames(
                  styles.mosaicMedia,
                  entry.fields.assets.length === 3 ? styles.threeMosaicMedia : undefined,
                  entry.fields.assets.length === 4 ? styles.fourMosaicMedia : undefined
                )}
                style={{ aspectRatio: "3/4" }}
              >
                <MediaAsset entry={entry.fields.assets[1]} />
              </div>
            ) : null}
            {entry.fields.assets[2] ? (
              <div
                className={styles.mosaicMedia}
                style={{
                  aspectRatio: entry.fields.assets.length === 4 ? "3/4" : "1/1",
                }}
              >
                <MediaAsset entry={entry.fields.assets[2]} />
              </div>
            ) : null}
            {entry.fields.assets[3] ? (
              <div
                className={styles.mosaicMedia}
                style={{ aspectRatio: entry.fields.assets.length === 4 ? "1/1" : "3/4" }}
              >
                <MediaAsset entry={entry.fields.assets[3]} />
              </div>
            ) : null}
            {entry.fields.assets[4] ? (
              <div className={styles.mosaicMedia} style={{ aspectRatio: "1/1" }}>
                <MediaAsset entry={entry.fields.assets[4]} />
              </div>
            ) : null}
          </>
        ) : null}
        {/* for editorial banner */}
        {entry.fields.assets && entry.fields.mosaicVariant === "mosaic_8" ? (
          <>
            {entry.fields.assets[0] ? (
              <div className={styles.mosaicMedia} style={{ aspectRatio: "1/1" }}>
                <MediaAsset entry={entry.fields.assets[0]} />
              </div>
            ) : null}
            {entry.fields.assets[1] ? (
              <div className={styles.mosaicMedia} style={{ aspectRatio: "1/1" }}>
                <MediaAsset entry={entry.fields.assets[1]} />
              </div>
            ) : null}
            <div className={styles.mosaicMedia} style={{ gridRow: "1 / 3", gridColumn: "2" }}>
              <MediaAsset entry={entry.fields.assets[2]} />
            </div>
          </>
        ) : null}
        {/* for editorial banner */}
        {entry.fields.mosaicVariant === "mosaic_9" && entry.fields.socialIDs ? (
          <div className={classNames(styles.mosaicMedia, styles.arrowsMosaic_9)}>
            <MosaicSocialWall socialIDs={entry.fields.socialIDs} />
          </div>
        ) : null}
      </motion.div>
    </AnimatePresence>
  );
}
