import "swiper/css";

import { KikRestVerbs } from "@kikocosmeticsorg/uc-api-nest-common-fe";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import classNames from "classnames";
import { useState } from "react";
import SwiperCore from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { CarouselArrows, updateArrowStatus } from "~/components/editorials/carousel-utils/carousel-utils";
import {
  SocialWallCarousel,
  SocialWallMissingData,
  SocialWallSkeleton,
} from "~/components/editorials/social-wall-utils/social-wall-utils";
import Logger from "~/services/logger/logger";
import { ApiConstants } from "~/shared/api/api-constants.class";
import { SocialWallResponse } from "~/shared/social-wall-response.interface";
import Constants from "~/utils/constants";

import SocialDialog from "../social-dialog";
import styles from "./mosaic-social-wall.module.scss";

type Props = {
  socialIDs: string[];
};

export default function MosaicSocialWall({ socialIDs }: Props) {
  const MAXIMUM_SLIDES = 3;

  const [socialWallIndex, setSocialWallIndex] = useState(0);
  const [socialWallDialogOpened, setSocialWallDialogOpened] = useState(false);
  const [arrowsStatus, setArrowsStatus] = useState({
    arrowL: false,
    arrowR: socialIDs.length > MAXIMUM_SLIDES,
  });
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore>();

  const onClose = () => {
    setSocialWallDialogOpened(false);
  };

  const buildSocialWallApiCall = (types: "instagram" | "tiktok" | string): Promise<SocialWallResponse> => {
    return fetch(ApiConstants.endpoints.socialWall, {
      method: KikRestVerbs.POST,
      headers: { "content-type": "application/json" },
      body: JSON.stringify({
        types,
      }),
    })
      .then((res) => res.json())
      .catch((err) => Logger.instance.error(err));
  };

  const { data: dataTiktok, isLoading: isLoadingTiktok }: UseQueryResult<SocialWallResponse> = useQuery({
    queryKey: ["socialWallTikTok"],
    queryFn: () => buildSocialWallApiCall("tiktok"),
  });

  const { data: dataInstagram, isLoading: isLoadingInstagram }: UseQueryResult<SocialWallResponse> = useQuery({
    queryKey: ["socialWallInstagram"],
    queryFn: () => buildSocialWallApiCall("instagram"),
  });

  const socialWallDataTikTok = dataTiktok?.data?.filter((data) => socialIDs?.includes(data.external_post_id));
  const socialWallDataInstagram = dataInstagram?.data?.filter((data) => {
    const idLinkInstagram = data.post_link.split("/");
    const lastSegment = idLinkInstagram.pop() || idLinkInstagram.pop();
    return socialIDs?.includes(lastSegment ?? "");
  });

  const socialWallData = socialWallDataTikTok?.concat(socialWallDataInstagram ?? []);

  return (
    <>
      <div className={styles.carouselContainer}>
        <Swiper
          onSwiper={setSwiperInstance}
          onTransitionEnd={(currentSwiper) =>
            socialIDs.length > MAXIMUM_SLIDES ? setArrowsStatus(updateArrowStatus(currentSwiper)) : null
          }
          slidesPerView={1.2}
          spaceBetween={12}
          speed={Constants.SWIPER_TRANSITION_DURATION}
          breakpoints={{
            640: {
              slidesPerView: 2.2,
            },
            900: {
              slidesPerView: MAXIMUM_SLIDES,
            },
          }}
        >
          {socialWallData ? (
            socialWallData.map((data, index) => (
              <SwiperSlide key={data.id} className={styles.swiperSlide}>
                <SocialWallCarousel
                  data={data}
                  index={index}
                  setSocialWallDialogOpened={setSocialWallDialogOpened}
                  socialWallDialogOpened={socialWallDialogOpened}
                  setSocialWallIndex={setSocialWallIndex}
                />
              </SwiperSlide>
            ))
          ) : isLoadingTiktok && isLoadingInstagram ? (
            Array.from({ length: MAXIMUM_SLIDES }).map((_, i) => (
              <SwiperSlide key={i} className={styles.initialSlide}>
                <SocialWallSkeleton />
              </SwiperSlide>
            ))
          ) : (
            <SocialWallMissingData />
          )}
        </Swiper>
        {swiperInstance && socialWallData ? (
          <CarouselArrows swiperInstance={swiperInstance} arrowsStatus={arrowsStatus} className={styles.arrows} />
        ) : null}
      </div>
      <SocialDialog
        isOpen={socialWallDialogOpened}
        onClose={onClose}
        externalPostId={socialWallData?.[socialWallIndex]?.external_post_id ?? ""}
        name={socialWallData?.[socialWallIndex]?.external_name ?? ""}
        type={socialWallData?.[socialWallIndex]?.type ?? ""}
        postLink={socialWallData?.[socialWallIndex]?.post_link ?? ""}
      />
    </>
  );
}
